import React, { useContext, useEffect, useState, useRef } from "react";
import "./ProductList.css";
import { CartContext } from "../../contexts/ShoppingCartContext";
import { useLocation } from 'react-router-dom';
import IconProm from "../../assets/images/branding/Promo-Icon.png";
import axios from 'axios';

export const ProductList = () => {
  const [inputValues, setInputValues] = useState({});
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const {
    allProducts,
    setAllProducts,
    total,
    setTotal,
    setCountProducts,
    filterProduct,
    search,
    setInventory
  } = useContext(CartContext);

  const location = useLocation();
  const productosRef = useRef(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const sortedProducts = filterProduct.sort((a, b) => {
          if (a.Promotion === 'Si' && b.Promotion === 'No') return -1;
          if (a.Promotion === 'No' && b.Promotion === 'Si') return 1;
          return 0;
        });
        setProducts(sortedProducts);

      } catch (error) {
        setError("Error al obtener los productos");
        console.error("Error al obtener los productos", error);
      }
    };

    const fetchInventory = async () => {
      try {
        const response = await axios.get("https://zoho.accsolutions.tech/API/v1/Inventario_berry");
        const { data } = await response.data;
        setInventory(data);
      } catch (error) {
        console.log(`Error al traer los productos del inventario - error ${error.message}`);
      }
    }
    fetchInventory();
    fetchProducts();

  }, [filterProduct, setInventory, products]);

  useEffect(() => {
    if (location.state?.scrollToProductos && productosRef.current) {
      productosRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const handleInputChange = (event, produId) => {
    const value = event.target.value;
    setInputValues(prevInpuntValues => ({
      ...prevInpuntValues,
      [produId]: value
    }));
  };

  const onAddProducts = product => {
    const inputValue = inputValues[product.id] || 1;
    if (allProducts.find(item => item.id === product.id)) {
      const updatedProducts = allProducts.map(item =>
        item.id === product.id
          ? { ...item, quantity: parseInt(item.quantity) + parseInt(inputValue) }
          : item
      );

      setTotal(total + parseInt(inputValue) * parseInt(product.price));
      setCountProducts(getTotalQuantity());
      setAllProducts(updatedProducts);
    } else {
      const newProduct = { ...product, quantity: parseInt(inputValue) };
      setTotal(total + parseInt(product.price) * parseInt(inputValue));
      setCountProducts(getTotalQuantity());
      setAllProducts([...allProducts, newProduct]);
    }
  };

  const getTotalQuantity = () => {
    return allProducts.reduce((acc, product) => acc + product.quantity, 1);
  };

  const renderProducts = () => {
    return (
      <div className="product-cards">
        {products.length > 0 ?
          products.flatMap((product) =>
            products.length > 0 ? (
              <div className="product-card" key={product.id}>
                <div className="product-card-top ">
                  {product.Promotion === "Si" ? (
                    <img
                      className="promotion-label"
                      src={IconProm}
                      alt="Etiqueta de Promoción."
                    ></img>

                  ) : null}
                  <div className="product-image">
                    <img src={product.image} alt="Etiqueta de Producto." />
                  </div>
                </div>
                <div className="product-card-bottom">
                  <div className="details">
                    <span className="title"> {product.name}</span><br></br>
                  </div>
                  <div className="card-product-buy-button">
                    <span className="price">${new Intl.NumberFormat('es-CL').format(product.price)}</span>
                    <button className="product-add" onClick={() => onAddProducts(product)}>
                      {allProducts.find(item => item.id === product.id) ?
                        <span>
                          {allProducts.find(item => item.id === product.id)?.quantity}
                        </span>
                        : null}
                      <i className="fa-solid fa-cart-shopping" />
                    </button>
                  </div>
                </div>
              </div>
            ) : ""
          ) : (
            <p>Revisa la ortografía de la palabra o utiliza palabras clave.</p>
          )
        }
      </div>
    );
  };

  return (
    <>
      <section className="product-section" id="productos" ref={productosRef}>
        <div className="product-header">
          <div className="product-title">
            <h2>
              {!search.length > 0 ? "Nuestro Portafolio" : !products.length > 0 ? `No hay productos que coincidan con tu búsqueda de: "${search}".` : `Busqueda relacionada de: "${search}".`}
            </h2>
          </div>
        </div>
        {renderProducts()}
      </section>
    </>
  );
};
