export const discountCoupon = (allProducts, data_discount, total)  => {
    let total_discount = 0;

    allProducts.forEach(product => {         
    
        let costo_discount = Math.round(product.price / product.amount - Math.round( (product.price / product.amount) * (data_discount[0].Porcentaje / 100) ));
        let price_discount = (costo_discount * product.amount);
        total_discount += price_discount * product.quantity; 
    });
    
    return total_discount !== 0 ? total_discount : 0;

}