// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Animación de entrada */
.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  /* Animación de salida */
  .fade-out {
    opacity: 1;
    animation: fadeOut 0.5s forwards;
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
  
  /* Animación general para los elementos */
  .fade-transition {
    transition: opacity 1s;
  }
  `, "",{"version":3,"sources":["webpack://./src/views/home/Home.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,UAAU;IACV,+BAA+B;EACjC;;EAEA;IACE;MACE,UAAU;IACZ;EACF;;EAEA,wBAAwB;EACxB;IACE,UAAU;IACV,gCAAgC;EAClC;;EAEA;IACE;MACE,UAAU;IACZ;EACF;;EAEA,yCAAyC;EACzC;IACE,sBAAsB;EACxB","sourcesContent":["/* Animación de entrada */\n.fade-in {\n    opacity: 0;\n    animation: fadeIn 0.5s forwards;\n  }\n  \n  @keyframes fadeIn {\n    to {\n      opacity: 1;\n    }\n  }\n  \n  /* Animación de salida */\n  .fade-out {\n    opacity: 1;\n    animation: fadeOut 0.5s forwards;\n  }\n  \n  @keyframes fadeOut {\n    to {\n      opacity: 0;\n    }\n  }\n  \n  /* Animación general para los elementos */\n  .fade-transition {\n    transition: opacity 1s;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
