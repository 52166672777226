import React, { useState, useEffect, useContext } from 'react';
import './carousel.css';
import { CartContext } from '../../contexts/ShoppingCartContext';
import IconProm from "../../assets/images/branding/Promo-Icon.png";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [resetInterval, setResetInterval] = useState(false);
  const [touchStart, setTouchStart] = useState(0); // Estado para la posición inicial del toque
  const [touchEnd, setTouchEnd] = useState(0); // Estado para la posición final del toque

  const { allProducts, setAllProducts, total, setTotal, setCountProducts, productList } = useContext(CartContext);
  const products = productList.filter(item => item.Promotion === 'Si');
  // const products = productList;
  // console.log(window.innerWidth)
  const getItemsToShow = (width) => {
    if (width > 1200) return 5;
    if (width > 768) return 4;
    if (width > 480) return 3;
    return 1;
  };
  const [itemsToShow, setItemsToShow] = useState(getItemsToShow(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setItemsToShow(getItemsToShow(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onAddProducts = (product) => {
    const inputValue = parseInt(inputValues[product.id]) || 1;
    if (allProducts.find((item) => item.id === product.id)) {
      const updatedProducts = allProducts.map((item) =>
        item.id === product.id
          ? { ...item, quantity: item.quantity + inputValue }
          : item
      );
      setTotal(total + inputValue * product.price);
      setCountProducts(getTotalQuantity());
      setAllProducts(updatedProducts);
    } else {
      const newProduct = { ...product, quantity: inputValue };
      setTotal(total + product.price * inputValue);
      setCountProducts(getTotalQuantity());
      setAllProducts([...allProducts, newProduct]);
    }
  };

  const getTotalQuantity = () => {
    return allProducts.reduce((acc, product) => acc + product.quantity, 0);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      setResetInterval(!resetInterval);
      return prevIndex + 1 > products.length - itemsToShow ? 0 : prevIndex + 1;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      setResetInterval(!resetInterval);
      return prevIndex - 1 < 0 ? products.length - itemsToShow : prevIndex - 1;
    });
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 4000);
    return () => clearInterval(interval);
  }, [products.length, resetInterval]);

  // Funciones para manejar el deslizamiento
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      nextSlide(); // Si desliza a la izquierda, pasa al siguiente slide
    }
    if (touchStart - touchEnd < -50) {
      prevSlide(); // Si desliza a la derecha, vuelve al slide anterior
    }
  };
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  
  const renderProducts = () => {
    return (
      <div
        className="carousel-container"
        onTouchStart={handleTouchStart} // Inicia el toque
        onTouchMove={handleTouchMove}   // Mueve el toque
        onTouchEnd={handleTouchEnd}     // Finaliza el toque
      >
        <div className="carousel-slide">
          {products.slice(currentIndex, currentIndex + itemsToShow).map((product, index) => (
            // <div className="carousel-item" key={index} 
            // >
            // <div className="col-promotion-card" >
            <div className="promotion-card" key={index}
              style={{ width: `calc(100% / ${itemsToShow} )` }}
            >
              <div className="promotion-card-top">
                <img className="promotion-label" src={IconProm} alt="Etiqueta de Promoción." />
                <div className="product-image">
                  <img src={product.image} alt={`Slide ${currentIndex + 1}`} />
                </div>
              </div>
              <div className="promotion-card-bottom">
                <div className='details'>
                  <span className="title">{product.name}</span>
                </div>
                <div className="card-product-buy-button">
                  <span className="price">${new Intl.NumberFormat('es-CL').format(product.price)}</span>
                  <button className="product-add" onClick={() => onAddProducts(product)}>
                    {allProducts.find(item => item.id === product.id) ? (
                      <span>{allProducts.find(item => item.id === product.id).quantity}</span>
                    ) : null}
                    <i className="fa-solid fa-cart-shopping"></i>
                  </button>
                </div>
              </div>
            </div>
            // </div>
            // </div>
          ))}


        </div>
        {itemsToShow === 1 && (
          <div className='indicativo'>
            {products.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
              ></span>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <section className="promotion-section">
        <div className="promotion-header">
          <div className="promotion-title">
            <h2>Nuestras Ofertas</h2>
          </div>
          <div className="promotion-more">
            <button className="promotion-button-more">Ver más</button>
          </div>
        </div>
        <div className="promotion-content">
          {productList.length > 0 ? (
            renderProducts()
          ) : (
            <p>{error}</p>
          )}
          {products.length > itemsToShow && (
            <>
              <button className="promotion-button prev-btn" onClick={prevSlide}>
                <i className="fa-solid fa-arrow-left"></i>
              </button>
              <button className="promotion-button next-btn" onClick={nextSlide}>
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Carousel;
