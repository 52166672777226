// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopping-cart-container {
  width: 80%;
  margin: 0 auto 50px;
  padding: 0 10px;
}

.shopping-cart-container h1 {
  text-align: center;
  margin: 0px auto 25px;
  font-family: "Lexend Deca", sans-serif;
  color: var(--color-text-dark);
}

.shopping-cart-container .shopping-cart-content {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .shopping-cart-container {
    width: 90%;
    padding: 0 !important;
  }
  .shopping-cart-container .shopping-cart-content {
    flex-direction: column;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .shopping-cart-container {
    width: 90%;
    padding: 0 !important;
  }
  .shopping-cart-container .shopping-cart-content {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/time-line-cart/TimeLineCart.css"],"names":[],"mappings":"AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,sCAAsC;EACtC,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE;IACE,UAAU;IACV,qBAAqB;EACvB;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,UAAU;IACV,qBAAqB;EACvB;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["@import url(\"../../App.css\");\n\n.shopping-cart-container {\n  width: 80%;\n  margin: 0 auto 50px;\n  padding: 0 10px;\n}\n\n.shopping-cart-container h1 {\n  text-align: center;\n  margin: 0px auto 25px;\n  font-family: \"Lexend Deca\", sans-serif;\n  color: var(--color-text-dark);\n}\n\n.shopping-cart-container .shopping-cart-content {\n  display: flex;\n  justify-content: space-between;\n}\n\n@media only screen and (max-width: 600px) {\n  .shopping-cart-container {\n    width: 90%;\n    padding: 0 !important;\n  }\n  .shopping-cart-container .shopping-cart-content {\n    flex-direction: column;\n  }\n}\n\n@media only screen and (min-width: 601px) and (max-width: 1024px) {\n  .shopping-cart-container {\n    width: 90%;\n    padding: 0 !important;\n  }\n  .shopping-cart-container .shopping-cart-content {\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
