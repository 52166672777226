import { Routers } from "./router/Routers";
import './App.css';

function App() {
  return (
    <>
      <Routers/>
    </>
  );
}

export default App;

