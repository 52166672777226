// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-user-container {
  width: 70%;
  margin: 0px auto 50px;
  padding: 0px 10px;
}

.data-user-container h1 {
  text-align: center;
  margin: 0px auto 25px;
  font-family: "Lexend Deca", sans-serif;
  color: var(--brown-dark);
}

.data-user-container .data-user-content {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .data-user-container {
    width: 90%;
    padding: 0 !important;
  }
  .data-user-container .data-user-content {
    flex-direction: column;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .data-user-container {
    width: 90%;
    padding: 0 !important;
  }
  .data-user-container .data-user-content {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/time-line-data/TimeLineData.css"],"names":[],"mappings":"AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,sCAAsC;EACtC,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,UAAU;IACV,qBAAqB;EACvB;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,UAAU;IACV,qBAAqB;EACvB;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["@import url(\"../../App.css\");\n\n.data-user-container {\n  width: 70%;\n  margin: 0px auto 50px;\n  padding: 0px 10px;\n}\n\n.data-user-container h1 {\n  text-align: center;\n  margin: 0px auto 25px;\n  font-family: \"Lexend Deca\", sans-serif;\n  color: var(--brown-dark);\n}\n\n.data-user-container .data-user-content {\n  width: 100%;\n  height: auto;\n}\n\n@media only screen and (max-width: 600px) {\n  .data-user-container {\n    width: 90%;\n    padding: 0 !important;\n  }\n  .data-user-container .data-user-content {\n    flex-direction: column;\n  }\n}\n\n@media only screen and (min-width: 601px) and (max-width: 1024px) {\n  .data-user-container {\n    width: 90%;\n    padding: 0 !important;\n  }\n  .data-user-container .data-user-content {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
