// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lexend+Deca:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-container {
  width: 100%;
  height: auto;
}

.cart-container .container {
  width: 80%;
  margin: 0 auto;
}

.cart-container .container h1 {
  text-align: center;
  margin: 30px auto;
  font-family: "Lexend Deca", sans-serif;
}

.cart-container .cart-content {
  display: flex;
  justify-content: space-between;
}




`, "",{"version":3,"sources":["webpack://./src/views/cart/Cart.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lexend+Deca:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap\");\n\n.cart-container {\n  width: 100%;\n  height: auto;\n}\n\n.cart-container .container {\n  width: 80%;\n  margin: 0 auto;\n}\n\n.cart-container .container h1 {\n  text-align: center;\n  margin: 30px auto;\n  font-family: \"Lexend Deca\", sans-serif;\n}\n\n.cart-container .cart-content {\n  display: flex;\n  justify-content: space-between;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
