// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-blue-dark: #172E58;
  --color-blue-cream: #E5EFFF;
  --color-blue-cream-special: #C7DAFD;
  --color-blue-circle: #9ABDFF; 
  --color-blue-aux: #004CD6;
  --color-border-gray: #D9D9D9;
  --color-border-blue: #004CD6;
  --color-text-dark: #000000;
  --color-white-main: #ffffff;
  --color-white-cream: #F4F4F4;
  --color-gray-event: #C5C5C5;
  --color-text-gray: #616161;
  --color-text-blue: #004CD6;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,mCAAmC;EACnC,4BAA4B;EAC5B,yBAAyB;EACzB,4BAA4B;EAC5B,4BAA4B;EAC5B,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,2BAA2B;EAC3B,0BAA0B;EAC1B,0BAA0B;AAC5B","sourcesContent":[":root {\n  --color-blue-dark: #172E58;\n  --color-blue-cream: #E5EFFF;\n  --color-blue-cream-special: #C7DAFD;\n  --color-blue-circle: #9ABDFF; \n  --color-blue-aux: #004CD6;\n  --color-border-gray: #D9D9D9;\n  --color-border-blue: #004CD6;\n  --color-text-dark: #000000;\n  --color-white-main: #ffffff;\n  --color-white-cream: #F4F4F4;\n  --color-gray-event: #C5C5C5;\n  --color-text-gray: #616161;\n  --color-text-blue: #004CD6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
