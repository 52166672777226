// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrolling-text {
display: flex;
justify-content: flex-start;
background-color: var(--color-blue-dark);
overflow: hidden;
padding: 10px 0px;
}

.scrolling-text span {
  font-size: 14px;
  color: var(--color-white-main);
  text-transform: uppercase;
  font-weight: bolder;
  white-space: nowrap;
  letter-spacing: 2px;
  animation: scroll 30000ms linear infinite;
  font-family: 'Poppins', sans-serif;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/scrolling-text/ScrollingText.css"],"names":[],"mappings":"AAEA;AACA,aAAa;AACb,2BAA2B;AAC3B,wCAAwC;AACxC,gBAAgB;AAChB,iBAAiB;AACjB;;AAEA;EACE,eAAe;EACf,8BAA8B;EAC9B,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,yCAAyC;EACzC,kCAAkC;AACpC;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":["@import url(../../../App.css);\n\n.scrolling-text {\ndisplay: flex;\njustify-content: flex-start;\nbackground-color: var(--color-blue-dark);\noverflow: hidden;\npadding: 10px 0px;\n}\n\n.scrolling-text span {\n  font-size: 14px;\n  color: var(--color-white-main);\n  text-transform: uppercase;\n  font-weight: bolder;\n  white-space: nowrap;\n  letter-spacing: 2px;\n  animation: scroll 30000ms linear infinite;\n  font-family: 'Poppins', sans-serif;\n}\n\n@keyframes scroll {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
